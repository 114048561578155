import { useState, useEffect } from 'react';
import { Modal, Button} from 'react-bootstrap';

interface Props {
    title: string,
    message: string,
    show: boolean,
    functionConfirm: () => void;
    functionClose: () => void;
}

export default function ConfirmModal(props: Props) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show])

    return (
        <Modal show={show} onHide={props.functionClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{ props.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>{ props.message }</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={props.functionClose}>
                Cancelar
            </Button>
            <Button variant="primary" onClick={props.functionConfirm}>
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>
    )
}