import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import IsGerente from 'utils/Profiles';
import { useNavigate } from "react-router";

function NavBarMenu() {

  const navigate = useNavigate();

  const handleLogout = () => {
    window.localStorage.clear();
    window.location.href = '/';
  }

  const link = (uri: string) => {
    navigate(uri)
  }

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Essencial</Navbar.Brand>
          <Nav className="me-auto">
            <NavDropdown title="Cadastros" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => link('/company-list')}>Empresa</NavDropdown.Item>
              <NavDropdown.Item onClick={() => link('/licence-list')}>Licença</NavDropdown.Item>
              <NavDropdown.Item onClick={() => link('/user-list')}>Usuários</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <Nav.Link onClick={handleLogout}>Sair</Nav.Link>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBarMenu;