import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Container, Card, Form, Button, Row, Col } from 'react-bootstrap';
import { ICompany } from 'types/ICompany';
import { MaskFields } from 'utils/Util';
import { GetCompany } from 'utils/Request';
import Api from 'utils/Api';
import AlertModal from 'components/AlertModal';
import NavBarMenu from 'components/NavBarMenu';
import { ILicence } from 'types/ILicence';

export default function LicenceRegister() {

    const navigate = useNavigate();

    const [fields, setFields] = useState<ILicence>({
        id: 0,
        expiracao: new Date().toISOString().split('T')[0],
        usuarios: 0,
        emissaoFiscal: 0,
        empresa: null
    });

    const [companies, setCompanies] = useState<ICompany[]>();

    const handleFieldsChange = (e: React.ChangeEvent<HTMLInputElement>) => setFields({
        ...fields,
        [e.currentTarget.name]: MaskFields(e.currentTarget.name, e.target.value)
    })

    //State
    const { state }  = useLocation();
    const licence = state as ILicence;

    //Alert
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        loadLicence(licence);
        loadCompanies();
    }, [licence])
    
    const loadCompanies = () => {
        GetCompany().then(response => {
            if(response.status === 200) setCompanies(response.data);
        })
    }

    const findCompanyById = (id: number) => {
        var company = companies?.find(c => c.id === id);
        return company;
    }

    const loadLicence = (user: ILicence) => {
        if(user) setFields(user)
    }
  
    const handleRegister = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault();
        Api.post("/licenca", {
            ...fields,
            empresa: fields.empresa == null ? (companies ? companies[0] : null) : fields.empresa
        })
        .then(response => {
            navigate('/licence-list')
        })
        .catch((error) => {
            setShowAlertMessage(true);
            setAlertMessage(error.response.data.message);
        })
    }

    const handleUpdate = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault();
        Api.put("/licenca", fields)
        .then(response => {
            console.log(fields);    
            navigate('/licence-list')
        })
        .catch((error) => {
            setShowAlertMessage(true);
            setAlertMessage(error.response.data.message);
        })
    }
    
    return (
        <>
            <NavBarMenu />
            <AlertModal show={showAlertMessage} variant='danger' message={alertMessage}/>
            <Container className="mt-3">
                <Card bg={'dark'} text={'light'}>
                    <Card.Header>Cadastro de Licença</Card.Header>
                    <Card.Body>
                        <Form onSubmit={licence === null ? handleRegister : handleUpdate} autoComplete="new-password">
                            <Form.Group className="mb-3" controlId="empresa">
                                <Form.Label>Empresa</Form.Label>
                                <Form.Select name='empresa' value={fields.empresa?.id} onChange={event => setFields({ ...fields, empresa: findCompanyById(Number(event.target.value)) })}>
                                    {companies?.map((company) => (
                                        <option key={company.id} value={company.id}>{ company.razaosocial }</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Row>
                                <Col xs="12" lg="3" md="5">
                                    <Form.Group className="mb-3" controlId="companyName">
                                        <Form.Label>Data de expiração</Form.Label>
                                        <Form.Control required name="expiracao" value={fields.expiracao} onChange={handleFieldsChange} type="date" placeholder="Digite a data de expiração da licença" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12" md="3">
                                    <Form.Group className="mb-3" controlId="fiscalIssuance">
                                        <Form.Check type="checkbox" label="Emissão Fiscal" checked={fields.emissaoFiscal === 1} onChange={event => setFields({...fields, emissaoFiscal: (event.target.checked ? 1 : 0)})}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            <Button variant="secondary" type="submit">
                                {licence === null ? 'Cadastrar' : 'Atualizar'}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}