import { useEffect, useState } from 'react'
import { Button, Card, Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import Api from 'utils/Api';
import ConfirmModal from 'components/ConfirmModal';
import NavBarMenu from 'components/NavBarMenu';
import { ICompany } from 'types/ICompany';
import { GetCompany } from 'utils/Request';
import AlertModal from 'components/AlertModal';

export default function CompanyList() {

    const navigate = useNavigate();
    const [companies, setCompanies] = useState<ICompany[]>();
    const [companySelected, setCompanySelected] = useState<ICompany | undefined>();
    const [showDialog, setShowDialog] = useState(false);

    //Alert
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleNewCompany = () => {
        navigate("/company-register")
    }

    useEffect(() => {
        GetCompany()
        .then(response => {
            if(response.status === 200) {
                setCompanies(response.data);
            }
        })
    }, [])

    const handleConfirmDeleteDialog = (company: ICompany | undefined) => {
        if(company) {
            setCompanySelected(company);
            setShowDialog(true);
            Api.delete(`/empresa/cpf-cnpj/${company!.cpfCnpj}`)
            .then(response => {
                companies?.splice(companies.findIndex(ind => ind.cpfCnpj === company!.cpfCnpj));
                navigate('/company-list')
            })
            .catch((error) => {
                setShowAlertMessage(true);
                setAlertMessage(error.response.data.message);
            })

            navigate('/company-list')    
        }

        setShowDialog(false);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    }

    const handleEdit = (company: ICompany) => {
        navigate('/company-register', {state: company})
    }

    const showDeleteConfirmation = (company: ICompany) => {
        setCompanySelected(company);
        setShowDialog(true);
    }
    
    return (
        <>
            <ConfirmModal 
                show={showDialog} 
                title={'Excluir empresa'}
                message={`Tem certeza que deseja excluir a empresa ${companySelected?.nome}?`} 
                functionConfirm={() => handleConfirmDeleteDialog(companySelected)}
                functionClose={() => handleCloseDialog()}
            />
            <NavBarMenu />
            <AlertModal show={showAlertMessage} variant='danger' message={alertMessage}/>
            <Container className="mt-3">
                <Card bg={'dark'} text={'light'}>
                    <Card.Header>Empresas Cadastradas</Card.Header>
                    <Card.Body>
                    
                    <Button variant="secondary mb-2" onClick={handleNewCompany}>
                        Nova Empresa
                    </Button>

                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th className='col-4'>Nome</th>
                                <th className='col-2'>Cnpj</th>
                                <th className='col-2'>Telefone</th>
                                <th className='col-2'>E-mail</th>
                                <th className='col-2'>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                        {companies?.map((company, index) => (
                            <tr key={ index }>
                                <td>{ company.nome }</td>
                                <td>{ company.cpfCnpj }</td>
                                <td>{ company.fone }</td>
                                <td>{ company.email }</td>
                                <td>
                                    <Button variant="outline-secondary" onClick={() => handleEdit(company)}>Editar</Button>{' '}
                                    <Button variant="outline-danger" onClick={() => showDeleteConfirmation(company)}>Excluir</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}