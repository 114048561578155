import AlertModal from 'components/AlertModal';
import { useState } from 'react'
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import Api from 'utils/Api';

export default function Auth() {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    //Alert
    const [showAlertMessage, setshowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    async function handleLogin(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        Api.post('/auth', { user: login, password: password })
          .then(function (response) {
            const data = response.data;
            window.localStorage.setItem('isAuthenticated', 'true');
            window.localStorage.setItem('token', data.token);
            window.localStorage.setItem('profile', data.profile);
            navigate("/main");
          })
          .catch(error => {
            setAlertMessage('Usuário ou senha inválidos');
            setshowAlertMessage(true);
          })
    }

    return (
        <>
        <Container className="mt-0">
            <AlertModal show={showAlertMessage} variant='danger' message={alertMessage}/>
                <div className="Auth-form-container">
                    <form className="Auth-form" onSubmit={(e) => handleLogin(e)}>
                        <div className="Auth-form-content">
                        <h3 className="Auth-form-title">Essencial</h3>
                        <div className="form-group mt-3">
                            <label>Login</label>
                            <input
                            type="text"
                            className="form-control mt-1"
                            placeholder="Digite seu login"
                            onChange={evento => setLogin(evento.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Senha</label>
                            <input
                            type="password"
                            className="form-control mt-1"
                            placeholder="Digite sua senha"
                            onChange={evento => setPassword(evento.target.value)}
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-secondary">
                            Entrar
                            </button>
                        </div>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    )
}