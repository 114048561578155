import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Auth from "./components/Auth"
import ProtectedRoutes from "utils/ProtectedRoutes"
import UserRegister from "pages/Register/User/UserRegister"
import UserList from "pages/Register/User/UserList"
import CompanyList from "pages/Register/Company/CompanyList"
import CompanyRegister from "pages/Register/Company/CompanyRegister"
import LicenceList from "pages/Register/Licence/LicenceList"
import LicenceRegister from "pages/Register/Licence/LicenceRegister"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth" element={<Auth />} />
        
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<LicenceList />} /> 
          <Route path="/main" element={<LicenceList />} />
          <Route path="/licence-list" element={<LicenceList />} />
          <Route path="/licence-register" element={<LicenceRegister />} />
          <Route path="/user-list" element={<UserList />} /> 
          <Route path="/user-register" element={<UserRegister />} /> 
          <Route path="/company-list" element={<CompanyList />} />
          <Route path="/company-register" element={<CompanyRegister />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App