export const CepMask = (cep: String) => {
    return cep
            .replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1');
}

export const CnpjMask = (cnpj: String) => {
    return cnpj
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1');
}

export const PhoneMask = (phone: String) => {
    return phone
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
            .replace(/(\d{4})\d+?$/, '$1');
}

export const MaskFields = (field: string, value: string) => {
    switch(field) {
        case 'cep':
            return CepMask(value);
        case 'cpfCnpj':
            return CnpjMask(value);
        case 'fone':
            return PhoneMask(value);
        case 'telefone':
            return PhoneMask(value);
        default:
            return value;
    }
}

export const RemoveMask = (value: String | undefined) => {
    return value && 
            value
            .replace(/[^a-zA-Z0-9 ]/g, '');
}