import { FocusEvent, useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Api from "utils/Api";
import { useLocation, useNavigate } from 'react-router';
import AlertModal from 'components/AlertModal';
import NavBarMenu from 'components/NavBarMenu';
import { ICompany } from 'types/ICompany';
import { CnpjMask, MaskFields, RemoveMask } from 'utils/Util';
import { TaxRegimeEnum } from 'types/TaxRegimeEnum';

export default function CompanyRegister() {

    const navigate = useNavigate();

    const [fields, setFields] = useState<ICompany>({
        id: 0,
        enquadramento: TaxRegimeEnum[0],
        razaosocial:'',
        nome:'',
        cep:'',
        estado:'',
        cidade:'',
        endereco:'',
        numero:'',
        complemento:'',
        bairro:'',
        fone:'',
        cpfCnpj:'',
        inscricaoestadual:'',
        responsavel:'',
        email:'',
        site:''
    });
    
    const handleFieldsChange = (e: React.ChangeEvent<HTMLInputElement>) => setFields({
        ...fields,
        [e.currentTarget.name]: MaskFields(e.currentTarget.name, e.target.value)
    })

    //State
    const { state }  = useLocation();
    const company = state as ICompany;

    //Alert
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        loadCompany(company);        
    }, [company])
  
    const handleRegister = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault();
        Api.post("/empresa", {
            ...fields,
            cpfCnpj: RemoveMask(fields.cpfCnpj)
        }).then(response => {
            if(response.status === 200) {
                navigate('/company-list')
            }
        }).catch((error) => {
            setShowAlertMessage(true);
            setAlertMessage(error.response.data.message);
        })
    }

    const handleUpdate = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault();
        Api.put('/empresa',{
            ...fields,
            cpfCnpj: RemoveMask(fields.cpfCnpj)
        }).then(() => {
            navigate('/company-list')
        }).catch((error) => {
            setShowAlertMessage(true);
            setAlertMessage(error.response.data.message);
        })
    }

    const handleCep = (e: FocusEvent<HTMLInputElement>) => {
        var cep = e.target.value.replace("-", "");
        Api.get(`https://viacep.com.br/ws/${cep}/json/`, { }).then(response => {
            fillFields(response.data);
        })
    }

    const fillFields = (data : any) => {
        setFields({...fields, 
            estado: data.uf,
            cidade: data.localidade,
            endereco: data.logradouro,
            bairro: data.bairro,
            complemento: data.complemento
        });
    }

    const loadCompany = (company: ICompany) => {
        if(company) { 
            setFields({
                ...company,
                cpfCnpj: CnpjMask(company.cpfCnpj ? company.cpfCnpj : '')
            });
        }
    }
    
    return (
        <>
            <NavBarMenu />
            <AlertModal show={showAlertMessage} variant='danger' message={alertMessage}/>
            <Container className="mt-3">
                <Card bg={'dark'} text={'light'}>
                    <Card.Header>Cadastro de Empresa</Card.Header>
                    <Card.Body>
                        <Form onSubmit={company === null ? handleRegister : handleUpdate} autoComplete="new-password">
                            <Row>
                                <Col xs="12" lg="4" md="12">
                                    <Form.Group className="mb-3" controlId="taxation">
                                        <Form.Label>Enquadramento</Form.Label>
                                        <Form.Select value={TaxRegimeEnum[+fields.enquadramento]} onChange={event => setFields({...fields, enquadramento: TaxRegimeEnum[+event.target.value]})}>
                                            <option value={TaxRegimeEnum.SIMPLES_NACIONAL}>SIMPLES NACIONAL</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xs="12" lg="6" md="12">
                                    <Form.Group className="mb-3" controlId="companyName">
                                        <Form.Label>Razão Social</Form.Label>
                                        <Form.Control required name="razaosocial" value={fields.razaosocial} onChange={handleFieldsChange} type="text" placeholder="Digite a razão social da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" lg="6" md="12">
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control required name="nome" value={fields.nome} onChange={handleFieldsChange} type="text" placeholder="Digite o nome da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12" lg="3" md="12">
                                    <Form.Group className="mb-3" controlId="zipcode">
                                        <Form.Label>CEP</Form.Label>
                                        <Form.Control required name="cep" value={fields.cep} onBlur={handleCep} onChange={handleFieldsChange} type="text" placeholder="Digite o CEP da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" lg="4" md="12">
                                    <Form.Group className="mb-3" controlId="state">
                                        <Form.Label>Estado (UF)</Form.Label>
                                        <Form.Control required name="estado" value={fields.estado} maxLength={2} onChange={handleFieldsChange} type="text" placeholder="Digite o estado da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" lg="5" md="12">
                                    <Form.Group className="mb-3" controlId="city">
                                        <Form.Label>Cidade</Form.Label>
                                        <Form.Control required name="cidade" value={fields.cidade} onChange={handleFieldsChange} type="text" placeholder="Digite a cidade da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12" lg="10" md="12">
                                    <Form.Group className="mb-3" controlId="address">
                                        <Form.Label>Endereço</Form.Label>
                                        <Form.Control required name="endereco" value={fields.endereco} onChange={handleFieldsChange} type="text" placeholder="Digite endereço da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" lg="2" md="12">
                                    <Form.Group className="mb-3" controlId="number">
                                        <Form.Label>Número</Form.Label>
                                        <Form.Control required name="numero" value={fields.numero} onChange={handleFieldsChange} type="text" placeholder="Número" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12" lg="6" md="12">
                                    <Form.Group className="mb-3" controlId="district">
                                        <Form.Label>Bairro</Form.Label>
                                        <Form.Control required name="bairro" value={fields.bairro} onChange={handleFieldsChange} type="text" placeholder="Digite bairro da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" lg="6" md="12">
                                    <Form.Group className="mb-3" controlId="complement">
                                        <Form.Label>Complemento</Form.Label>
                                        <Form.Control name="complemento" value={fields.complemento} onChange={handleFieldsChange} type="text" placeholder="Digite um complemento para o endereço" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12" lg="4" md="12">
                                    <Form.Group className="mb-3" controlId="phone">
                                        <Form.Label>Telefone</Form.Label>
                                        <Form.Control required name="fone" value={fields.fone} onChange={handleFieldsChange} type="text" placeholder="Digite o telefone da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" lg="4" md="12">
                                    <Form.Group className="mb-3" controlId="state">
                                        <Form.Label>CNPJ</Form.Label>
                                        <Form.Control required name="cpfCnpj" value={fields.cpfCnpj} onChange={handleFieldsChange} type="text" placeholder="Digite o CNPJ da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" lg="4" md="12">
                                    <Form.Group className="mb-3" controlId="city">
                                        <Form.Label>Inscrição estadual</Form.Label>
                                        <Form.Control required name="inscricaoestadual" value={fields.inscricaoestadual} maxLength={30} onChange={handleFieldsChange} type="text" placeholder="Digite a inscrição estadual da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className="mb-3" controlId="responsible">
                                <Form.Label>Responsável</Form.Label>
                                <Form.Control required name="responsavel" value={fields.responsavel} onChange={handleFieldsChange} type="text" placeholder="Digite o responsável da empresa" autoComplete="new-password"/>
                            </Form.Group>

                            <Row>
                                <Col xs="12" lg="6" md="12">
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>E-mail</Form.Label>
                                        <Form.Control name="email" value={fields.email} onChange={handleFieldsChange} type="text" placeholder="Digite o e-mail da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" lg="6" md="12">
                                    <Form.Group className="mb-3" controlId="website">
                                        <Form.Label>Site</Form.Label>
                                        <Form.Control name="site" value={fields.site} onChange={handleFieldsChange} type="text" placeholder="Digite o site da empresa" autoComplete="new-password" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button variant="secondary" type="submit">
                                {company === null ? 'Cadastrar' : 'Atualizar'}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}