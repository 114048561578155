import axios from 'axios'

const Api = axios.create({
    baseURL: `http://walla4444.c44.integrator.host:7637/`,
    // baseURL: `http://localhost:8080/`,
    timeout: 10000
});

const requestHandler = (request: any) => {
    request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;  
  
    return request;
};

const responseHandler = (response: any) => {
    if (response.status === 401 || response.status === 403) {
        localStorage.clear();
        window.location.href = '/';
    }

    return response;
};

const errorHandler = (error: any) => {
    if (error.response.status === 403) {
        alert('Sessão expirada, faça login novamente')
        localStorage.clear();
        window.localStorage.clear();
        window.location.href = '/';
    }

    if(error.name === 'AxiosError') {
        // alert("Sessão Expirada")
        //localStorage.clear();
        // window.location.href = '/';
    }


    return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.

Api.interceptors.request.use(
    (request: any) => requestHandler(request),
    (error: any) => errorHandler(error)
);

Api.interceptors.response.use(
    (response: any) => responseHandler(response),
    (error: any) => errorHandler(error)
 );


// Step-4: Export the newly created Axios instance to be used in different locations.
export default Api;