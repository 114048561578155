import { useEffect, useState } from 'react'
import { Button, Card, Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import Api from 'utils/Api';
import { IUser } from 'types/IUser';
import ConfirmModal from 'components/ConfirmModal';
import NavBarMenu from 'components/NavBarMenu';

export default function UserList() {

    const navigate = useNavigate();
    const [users, setUsers] = useState<IUser[]>();
    const [userSelected, setUserSelected] = useState<IUser | undefined>();
    const [showDialog, setShowDialog] = useState(false);

    const handleNewUser = () => {
        navigate("/user-register")
    }

    useEffect(() => {
        Api.get("/usuario")
            .then(response => {
                if(response.status === 200) {
                    setUsers(response.data);
                }
            })
    }, [])

    const handleConfirmDeleteDialog = (user: IUser | undefined) => {
        if(user) {
            setUserSelected(user);
            setShowDialog(true);
            Api.delete(`/usuario/${user!.id}`)
            .then(response => {
                users?.splice(users.findIndex(ind => ind.id === user!.id));
                navigate('/user-list')
            })

            navigate('/user-list')    
        }

        setShowDialog(false);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    }

    const handleEdit = (user: IUser) => {
        navigate('/user-register', {state: user})
    }

    const showDeleteConfirmation = (user: IUser) => {
        setUserSelected(user);
        setShowDialog(true);
    }
    
    return (
        <>
            <ConfirmModal 
                show={showDialog} 
                title={'Excluir usuário'}
                message={`Tem certeza que deseja excluir o usuario ${userSelected?.nome}?`} 
                functionConfirm={() => handleConfirmDeleteDialog(userSelected)}
                functionClose={() => handleCloseDialog()}
            />
            <NavBarMenu />
            <Container className="mt-3">
                <Card bg={'dark'} text={'light'}>
                    <Card.Header>Usuários Cadastrados</Card.Header>
                    <Card.Body>
                    
                    <Button variant="secondary mb-2" onClick={handleNewUser}>
                        Novo Usuário
                    </Button>

                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th className='col-4'>Nome</th>
                                <th className='col-2'>Telefone</th>
                                <th className='col-2'>E-mail</th>
                                <th className='col-2'>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                        {users?.map((user, index) => (
                            <tr key={ index }>
                                <td>{ user.nome }</td>
                                <td>{ user.telefone }</td>
                                <td>{ user.email }</td>
                                <td>
                                    <Button variant="outline-secondary" onClick={() => handleEdit(user)}>Editar</Button>{' '}
                                    <Button variant="outline-danger" onClick={() => showDeleteConfirmation(user)}>Excluir</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}