import Alert from 'react-bootstrap/Alert';

interface Props {
    variant: string,
    message: string,
    show: boolean
}

export default function AlertModal(props: Props) {
    return (
        <>
        <Alert show={props.show} key={props.variant} variant={props.variant}>
            {props.message}
         </Alert>
        </>
    );
}