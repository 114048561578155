import { Navigate, Outlet } from "react-router";

const useAuth = () => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    return isAuthenticated;
}

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/auth" />
}

export default ProtectedRoutes;