import { useEffect, useState } from 'react'
import { Button, Card, Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import Api from 'utils/Api';
import ConfirmModal from 'components/ConfirmModal';
import NavBarMenu from 'components/NavBarMenu';
import { ILicence } from 'types/ILicence';
import AlertModal from 'components/AlertModal';

export default function LicenceList() {

    const navigate = useNavigate();
    const [licences, setLicences] = useState<ILicence[]>();
    const [licenceSelected, setLicenceSelected] = useState<ILicence | undefined>();
    const [showDialog, setShowDialog] = useState(false);

    //Alert
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleNewUser = () => {
        navigate("/licence-register")
    }

    useEffect(() => {
        Api.get("/licenca")
            .then(response => {
                if(response.status === 200) {
                    setLicences(response.data);
                }
            })
    }, [])

    const handleConfirmDeleteDialog = (licence: ILicence | undefined) => {
        if(licence) {
            setLicenceSelected(licence);
            setShowDialog(true);
            Api.delete(`/licenca/cnpj/${licence!.empresa?.cpfCnpj}`)
            .then(response => {
                licences?.splice(licences.findIndex(ind => ind.id === licence!.id));
                navigate('/licence-list')
            })
            .catch((error) => {
                setShowAlertMessage(true);
                setAlertMessage(error.response.data.message);
            })

            navigate('/licence-list')    
        }

        setShowDialog(false);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    }

    const handleEdit = (licence: ILicence) => {
        navigate('/licence-register', {state: licence})
    }

    const showDeleteConfirmation = (licence: ILicence) => {
        setLicenceSelected(licence);
        setShowDialog(true);
    }

    const formatDate = (date: string) => {
        var year = date.substring(0, 4);
        var month = date.substring(5, 7);
        var day = date.substring(8, 10);
        return day.concat("/").concat(month).concat("/").concat(year);
    }
    
    return (
        <>
            <ConfirmModal 
                show={showDialog} 
                title={'Excluir licença'}
                message={`Tem certeza que deseja excluir a licença da empresa ${licenceSelected?.empresa?.nome} (${licenceSelected?.empresa?.cpfCnpj}) ?`} 
                functionConfirm={() => handleConfirmDeleteDialog(licenceSelected)}
                functionClose={() => handleCloseDialog()}
            />
            <NavBarMenu />
            <AlertModal show={showAlertMessage} variant='danger' message={alertMessage}/>
            <Container className="mt-3">
                <Card bg={'dark'} text={'light'}>
                    <Card.Header>Licenças Cadastradas</Card.Header>
                    <Card.Body>
                    
                    <Button variant="secondary mb-2" onClick={handleNewUser}>
                        Nova Licença
                    </Button>

                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th className='col-4'>Empresa</th>
                                <th className='col-2'>Expiração</th>
                                <th className='col-2'>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                        {licences?.map((licence, index) => (
                            <tr key={ index }>
                                <td>{ licence.empresa?.razaosocial }</td>
                                <td>{ formatDate(licence.expiracao) }</td>
                                <td>
                                    <Button variant="outline-secondary" onClick={() => handleEdit(licence)}>Editar</Button>{' '}
                                    <Button variant="outline-danger" onClick={() => showDeleteConfirmation(licence)}>Excluir</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}