import { useEffect, useState } from 'react'
import Api from "utils/Api";
import { IUser } from "types/IUser"
import { useLocation, useNavigate } from 'react-router';
import { MaskFields } from 'utils/Util';
import NavBarMenu from 'components/NavBarMenu';
import AlertModal from 'components/AlertModal';
import { Container, Card, Form, Button } from 'react-bootstrap';

export default function UserRegister() {

    const navigate = useNavigate();

    const [fields, setFields] = useState<IUser>({
        id: 0,
        nome: '',
        email: '',
        senha: '',
        telefone: ''
    });

    const handleFieldsChange = (e: React.ChangeEvent<HTMLInputElement>) => setFields({
        ...fields,
        [e.currentTarget.name]: MaskFields(e.currentTarget.name, e.target.value)
    })

    //State
    const { state }  = useLocation();
    const user = state as IUser;

    //Alert
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        loadUser(user);
    }, [user])
    
    const loadUser = (user: IUser) => {
        if(user) setFields(user)
    }
  
    const handleRegister = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault();
        Api.post("/usuario", fields)
        .then(response => {
            navigate('/user-list')
        })
        .catch((error) => {
            setShowAlertMessage(true);
            setAlertMessage(error.response.data.message);
        })
    }

    const handleUpdate = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault();
        Api.put("/usuario", fields)
        .then(response => {
            console.log(fields);    
            navigate('/user-list')
        })
        .catch((error) => {
            setShowAlertMessage(true);
            setAlertMessage(error.response.data.message);
        })
    }
    
    return (
        <>
            <NavBarMenu />
            <AlertModal show={showAlertMessage} variant='danger' message={alertMessage}/>
            <Container className="mt-3">
                <Card bg={'dark'} text={'light'}>
                    <Card.Header>Cadastro de Usuário</Card.Header>
                    <Card.Body>
                        <Form onSubmit={user === null ? handleRegister : handleUpdate} autoComplete="new-password">
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control required name='nome' value={fields.nome} onChange={handleFieldsChange} type="text" placeholder="Digite o nome do usuário" autoComplete="new-password" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Telefone</Form.Label>
                                <Form.Control required name='telefone' value={fields.telefone} onChange={handleFieldsChange} type="text" placeholder="Digite o telefone do usuário" autoComplete="new-password"/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control required name='email' value={fields.email} onChange={handleFieldsChange} type="email" placeholder="Digite o e-mail do usuário" autoComplete="new-password"/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Senha</Form.Label>
                                    <Form.Control required disabled={user !== null} name='senha' value={fields.senha} onChange={handleFieldsChange} type="password" placeholder="Informe a senha do usuário" />
                                </Form.Group>

                            <Button variant="secondary" type="submit">
                                {user === null ? 'Cadastrar' : 'Atualizar'}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}